<template>
  <v-container
    id="users"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifier l’activité</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-menu
                  ref="form.menu"
                  v-model="form.menu"
                  :close-on-content-click="false"
                  :return-value.sync="form.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formDateAsText"
                      label="Date*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    show-week
                    show-current
                    locale="fr-fr"
                    color="green lighten-1"
                    :max="todayISO"
                    @change="$refs.menu.save(form.date)"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="7"
              >
                <v-autocomplete
                  v-model="form.taskId"
                  :items="activeTasks"
                  label="Tâche*"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="form.minutes"
                  label="Durée*"
                  required
                  suffix="minutes"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-model="form.customerId"
                  :items="activeCustomers"
                  clearable
                  label="Bénéficiaire"
                  item-value="id"
                  :item-text="item => item.last_name.toLocaleUpperCase() +' '+ item.first_name"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="form.serviceId"
                  :items="activeServices"
                  clearable
                  label="Mesure"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                offset-sm="6"
              >
                <v-autocomplete
                  v-model="form.clientId"
                  :items="activeClients"
                  clearable
                  label="Mandant"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="form.note"
                  filled
                  name="input-7-4"
                  label="Note"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indique les champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="sending"
            @click="saveActivity().then(() => closeDialog())"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-material-card
      class="px-5 py-3"
    >
      <template
        v-slot:heading
      >
        <div
          class="d-flex flex-wrap align-center"
          style="margin: -15px 0"
        >
          <div class="text-h4 font-weight-light">
            Mes activités
          </div>
          <v-spacer/>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="humanizedDates"
                label="Période"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              first-day-of-week="1"
              show-week
              show-current
              locale="fr-fr"
              color="green lighten-1"
              :max="todayISO"
              range
              @change="$refs.menu.save(dates)"
            />
          </v-menu>
        </div>
      </template>
      <v-simple-table>
        <thead>
        <tr>
          <th class="primary--text fit">
            Date
          </th>
          <th class="primary--text">
            Tâche
          </th>
          <th class="primary--text text-right fit">
            Durée
          </th>
          <th class="primary--text">
            Bénéficiaire
          </th>
          <th class="primary--text">
            Mesure
          </th>
          <th class="primary--text">
            Mandant
          </th>
          <th class="primary--text text-right fit">
            Actions
          </th>
        </tr>
        </thead>

        <tbody>
        <tr
          v-for="activity in nactivities"
          :key="activity.id"
        >
          <td>{{ activity.date }}</td>
          <td class="fit">
            {{ activity.task.name }}
          </td>
          <td class="text-right">
            {{ activity.minutes }}
          </td>
          <td>
            {{
              activity.customer ? (activity.customer.last_name.toUpperCase() + ' ' + activity.customer.first_name) : '-'
            }}
          </td>
          <td>{{ activity.service ? activity.service.name : '-' }}</td>
          <td>{{ activity.client ? activity.client.name : '-' }}</td>
          <td class="fit text-right">
            <v-btn
              icon
              text
              small
              title="éditer"
              dark
              @click="openEdit(activity)"
            >
              <v-icon
                color="primary"
                dark
              >
                mdi-pencil-outline
              </v-icon>
            </v-btn>

            <v-btn
              icon
              text
              small
              title="supprimer"
              dark
              @click="deleteActivity(activity)"
            >
              <v-icon
                color="error"
                dark
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import store from '../../../store'
  import { startOfWeek } from 'date-fns'
  import gql from 'graphql-tag'
  import { toDateISO } from '../../../utils'
  import { mapGetters } from 'vuex'
  import { STORAGE_JWT, STORAGE_JWT_REFRESH } from '../../../auth'
  import Vue from 'vue'

  export default {
    name: 'MyActivities',
    store,
    mounted () {
      Vue.prototype.$reloadMyActivities = () => {
        this.$apollo.queries.activities.refetch(
          {
            startDate: this.start,
            endDate: this.end,
            user: localStorage.getItem('me'),
          },
        )
      }
    },
    data () {
      const today = new Date()
      const start = toDateISO(startOfWeek(today, { weekStartsOn: 1 }))
      return {
        today,
        menu: false,
        sending: false,
        dates: [start, toDateISO(today)],
        nactivities: [],
        search: null,
        dialog: false,
        form: {
          id: null,
          menu: false,
          customerId: null,
          serviceId: null,
          taskId: null,
          date: null,
          minutes: null,
          note: '',
          task: null,
          customer: null,
          service: null,
          client: null,
        },
      }
    },
    computed: {
      start () {
        return this.dates[0]
      },
      end () {
        return this.dates[this.dates.length - 1]
      },
      todayISO () {
        return toDateISO(this.today)
      },
      humanizedDates () {
        if (this.dates[0] !== this.dates[1]) {
          return (new Date(this.dates[0])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          }) + ' au ' + (new Date(this.dates[1])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        } else {
          return (new Date(this.dates[0])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        }
      },
      formDateAsText () {
        return (new Date(this.form.date)).toLocaleDateString('fr-CH', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        })
      },
      ...mapGetters([
        'activeTasks',
        'activeCustomers',
        'activeServices',
        'activeClients',
      ]),
    },
    watch: {
      '$route.name': {
        handler () {
          this.$nextTick(() => this.$apollo.queries.activities.refetch(
            {
              startDate: this.start,
              endDate: this.end,
              user: localStorage.getItem('me'),
            },
          ))
        },
        deep: true,
        immediate: true,
      },
      dates () {
        this.$nextTick(() => this.$apollo.queries.activities.refetch(
          {
            startDate: this.start,
            endDate: this.end,
            user: localStorage.getItem('me'),
          },
        ))
      },
      dialog (newValue) {
        if (newValue) {
          this.now = new Date()
          this.$store.dispatch('fetch_collections')
        }
      },
    },
    methods: {
      openEdit (activity) {
        this.dialog = true
        this.form.id = activity.id
        this.form.date = activity.date
        this.form.minutes = activity.minutes
        this.form.taskId = activity.task?.id
        this.form.customerId = activity.customer?.id
        this.form.clientId = activity.client?.id
        this.form.serviceId = activity.service?.id
        this.form.note = activity.note
      },
      closeDialog () {
        this.form = {
          id: null,
          menu: false,
          customerId: null,
          serviceId: null,
          taskId: null,
          date: null,
          minutes: null,
          note: '',
          task: null,
          customer: null,
          service: null,
          client: null,
        }
        this.dialog = false
      },
      saveActivity () {
        this.sending = true
        return fetch(
          'https://api.chronophage.xenote.ch/items/activities/' + this.form.id,
          {
            method: 'PATCH',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
            body: JSON.stringify({
              minutes: this.form.minutes,
              date: this.form.date,
              task: this.form.taskId,
              customer: this.form.customerId,
              client: this.form.clientId,
              service: this.form.serviceId,
              note: this.form.note,
            }),
          },
        ).then(response => {
          this.sending = false
          this.$nextTick(() => this.$apollo.queries.activities.refetch(
            {
              startDate: this.start,
              endDate: this.end,
              user: localStorage.getItem('me'),
            },
          ))
          return response
        })
      },
      deleteActivity (activity) {
        this.$apollo.mutate({
          mutation: gql`
            mutation ($activityId: ID!) {
              delete_activities_item(id: $activityId) {
                id
              }
            }
          `,
          variables: {
            activityId: activity.id,
          },
        }).then((response) => {
          this.$nextTick(() => this.$apollo.queries.activities.refetch(
            {
              startDate: this.start,
              endDate: this.end,
              user: localStorage.getItem('me'),
            },
          ))
        })
      },
    },
    apollo: {
      activities () {
        return {
          query: gql`
          query ($startDate: String!, $endDate: String!, $user: String!) {
            activities (filter: { _and: [{ user: {id: { _eq: $user } }}, {date: { _gte: $startDate }}, {date: { _lte:  $endDate }}]} , sort: ["date"], limit: -1 ) {
              id
              date
              minutes
              note
              service {
                id
                name
              }
              task {
                id
                name
              }
              service {
                id
                name
              }
              customer {
                id
                first_name
                last_name
              }
              client {
                id
                name
              }
            }
          }
            `,
          variables: {
            startDate: this.start,
            endDate: this.end,
            user: localStorage.getItem('me'),
          },
          update (data) {
            this.nactivities = data.activities.filter(activity => activity.date <= this.end)
          },
          error (error) {
            const code = error.networkError?.statusCode
            if (code === 401) {
              localStorage.removeItem('jwt')
              this.$router.push({ name: 'Login' })
            }
          },
        }
      },
    },
  }
</script>

<style scoped>
.fit {
  width: 1%;
  white-space: nowrap;
}

.archived {
  background-color: #d2d2d2;
}
</style>
