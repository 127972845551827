/**
 * Convert a date object to 'YYYY-MM-DD' format, without timezone's gotcha
 *
 * @param date
 * @returns {string}
 */
export function toDateISO (date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

/**
 * Format minutes to 'HhMM'
 * @param minutes
 * @param contract
 * @returns {string}
 */
export function minutesToHoursMinutes (minutes, contract = false) {
  if (minutes === 0 && contract) {
    return '-'
  }
  return Math.trunc(minutes / 60) + 'h' + String(minutes % 60).padStart(2, '0')
}
